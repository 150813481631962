import { AxiosResponse } from "axios";
import { SetStateAction, useContext, useState} from "react";
import {FileWithPath} from "react-dropzone";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import {ComunicacionesContext, defaultSelected} from "..";
import {postData} from "../../../API/index";
import SuccessIcon from "../../../assets/icons/circle-check.svg";
import AlertModal from "../../../components/AlertModal/index";
import Button from "../../../components/Button";
import FileUploadButton from "../../../components/FileUploadButton";
import {useAuth} from "../../../context/AuthContext";
import {AlertModalType} from "../../../shared/types";

const EnviosTab = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const {selectedOption, setSelectedOption} = useContext(
      ComunicacionesContext
  );
  const [fileToUpload, setFileToUpload] = useState<FileWithPath | null>(null);
  const [modalConfig, setModalConfig] = useState<{
    title: string;
    type: AlertModalType;
    description?: string;
    onSubmit?: () => void;
    onClose?: () => void;
  } | null>(null);
  const {token} = useAuth();
  const navigate = useNavigate() as NavigateFunction;

  const [disableButton, setDisableButton] = useState(false);
  const changeDisableButton = (disableState: SetStateAction<boolean>) => setDisableButton(disableState);

  const [buttonText, setButtonText] = useState("Cargar");
  const changeText = (text: SetStateAction<string>) => setButtonText(text);


  const urlCarga = "/api/carga/parques/";

  const handleUpload = async () => {
    if (fileToUpload) {
      changeDisableButton(true);
      changeText("Cargando...");
      const formData = new FormData();
      formData.append("archivo", fileToUpload);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const data = {
        archivo: "",
      };

      const response: AxiosResponse = await postData(
        `${urlCarga}${selectedOption}/fichero`,
        formData,
        token,
        navigate,
        headers
      );

      if (response && response.status >= 200 && response.status < 300) {
        if (response.data.original.success) setUploadSuccess(true);
        else {
          const handleClose = () => {
            setModalConfig(null);
            //navigate(-1);
          };
          const config = {
            title: `Error`,
            type: "ok" as AlertModalType,
            onSubmit: handleClose,
            onClose: handleClose,
          };
          config.title = response.data.original.message;
          config.type = "error";
          config.onSubmit = handleClose;
          setModalConfig(config);
        }
      } else {
        console.log(
          "No se puede enviar el formulario. Asegúrate de seleccionar una opción y cargar un archivo."
        );
      }
      changeText("Cargar");
      changeDisableButton(false);
    } else {
      console.log("No se ha seleccionado ningún archivo.");
    }
  };

  const handleNewUpload = () => {
    setSelectedOption(defaultSelected);
    setUploadSuccess(false);
    setFileToUpload(null);
  };

  return (
    <div className="envios-container">
      {!uploadSuccess && (
        <div>
          <FileUploadButton
            type="archivo"
            onSubmit={(file) => setFileToUpload(file)}
          />

          <Button color="primary" isDisabled={disableButton} onClick={handleUpload}>
            {buttonText}
          </Button>
        </div>
      )}

      {uploadSuccess && (
        <div className="success-container">
          <img src={SuccessIcon} alt="Success Icon" />
          <p>Documento enviado a gestión de envíos</p>
          <Link to="/gestion-envios">
            <Button color="primary">Ir a gestión de envíos</Button>
          </Link>
          <Button color="transparent" onClick={handleNewUpload}>
            Realizar nuevo envío
          </Button>
        </div>
      )}

      {modalConfig && (
        <div className="error-container">
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={modalConfig.onSubmit}
            onClose={modalConfig.onClose}
          />
        </div>
      )}
    </div>
  );
};

export default EnviosTab;
