import { createContext, useState } from "react";
import CommunicationsIcon from "../../assets/icons/parques-blanco.svg";
import "../../assets/scss/pages/_communications.scss";
import Navbar from "../../components/Navbar";
import Select from "../../components/Select";
import ConsultasTab from "./Consultas";
import EnviosTab from "./Envios";
import { useAuth } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

export const defaultSelected = "inventario";
export const ComunicacionesContext = createContext({
  selectedOption: defaultSelected,
  setSelectedOption: (option: string) => {},
});
const options = [
  {
    text: "Inventario",
    value: "inventario",
    permission: "import-inventario",
  },
  /*{
    text: 'Actuaciones',
    value: 'actuaciones',
    permission: 'import-actuaciones'
  },*/
];

function ComunicacionesPage() {
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  const [tabSeleccionada, setTabSeleccionada] = useState("tab1");
  const { user, isAuthenticated } = useAuth();
  const filteredOptions =
    user !== "" && user.permisos
      ? options.filter((option: any) =>
          user.permisos.some(
            (permission: any) => permission.nombre === option.permission
          )
        )
      : [];

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <ComunicacionesContext.Provider
      value={{ selectedOption, setSelectedOption }}
    >
      <div className="communications-page">
        <div className="cabecera">
          <Navbar />
          <div className="title">
            <img src={CommunicationsIcon} alt="Parques" />
            <h2>Parques</h2>
          </div>
        </div>
        <div className="main-content">
          {/* TODO: Agregarle estilos al DIV */}
          <div style={{ width: 200, marginBlock: 20 }}>
            <Select
              label="Trabajar con"
              options={filteredOptions}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            />
          </div>
          <div className="tabs-container marginTop100">
            {/* Botones de las pestañas */}
            <button
              onClick={() => setTabSeleccionada("tab1")}
              className={
                tabSeleccionada === "tab1"
                  ? "tab-seleccionada"
                  : "tab-no-seleccionada"
              }
            >
              Envíos
            </button>
            <button
              onClick={() => setTabSeleccionada("tab2")}
              className={
                tabSeleccionada === "tab2"
                  ? "tab-seleccionada"
                  : "tab-no-seleccionada"
              }
            >
              Consultas
            </button>
          </div>

          <div className="tabs-content">
            {/* Contenido de la pestaña seleccionada */}
            {tabSeleccionada === "tab1" ? <EnviosTab /> : <ConsultasTab />}
          </div>
        </div>
      </div>
    </ComunicacionesContext.Provider>
  );
}

export default ComunicacionesPage;
