import React, { useState } from 'react';
import '../../assets/scss/pages/_gestion-usuarios.scss';
import Navbar from "../../components/Navbar";
import GestionUsuariosIcon from '../../assets/icons/users-blanco.svg';
import UsuariosTab from './Usuarios';
import RolesTab from './Roles';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const GestionUsuariosPage: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const [tabSeleccionada, setTabSeleccionada] = useState(tab || 'usuarios');
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();

  const handleTabClick = (tab: string) => {
    setTabSeleccionada(tab);
    navigate(`/gestion-usuarios/${tab}`);
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="gestion-usuarios-page">
      <div className="cabecera">
        <Navbar />
        <div className="title">
          <img src={GestionUsuariosIcon} alt="Gestión de usuarios" />
          <h2>Gestión de usuarios</h2>
        </div>
      </div>

      <div className="main-content">
        <div className="tabs-container">
          {/* Botones de las pestañas */}
          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'list-users')) && (
            <button onClick={() => handleTabClick('usuarios')} className={tabSeleccionada === 'usuarios' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
              Usuarios
            </button>
          )}

          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'list-roles')) && (
            <button onClick={() => handleTabClick('roles')} className={tabSeleccionada === 'roles' ? 'tab-seleccionada' : 'tab-no-seleccionada'}>
              Roles
            </button>
          )}
        </div>

        <div className="tabs-content">
          {/* Contenido de la pestaña seleccionada */}
          {(tab === 'usuarios') && (user && user.permisos.some((permiso: any) => permiso.nombre === 'list-users')) && (
            <UsuariosTab />
          )}
          {(tab === 'roles') && (user && user.permisos.some((permiso: any) => permiso.nombre === 'list-roles')) && (
            <RolesTab />
          )}
        </div>
      </div>
    </div>
  );
}

export default GestionUsuariosPage;