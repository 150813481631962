import React, { useState, useEffect, useMemo } from 'react';
import '../../../assets/scss/pages/_gestion-usuarios.scss';
import DataTable, { TableColumn } from 'react-data-table-component';
import EditIcon from '../../../assets/icons/edit.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import AddIcon from '../../../assets/icons/plus-circle.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import DefaultAvatarIcon from '../../../assets/icons/user.svg';
import CustomPagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { Role, User } from '../../../API/interfaces';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../../context/AuthContext';
import {deleteData, getData} from '../../../API/index';
import { appConfig } from '../../../config/app';
import { AlertModalType } from "../../../shared/types";
import AlertModal from "../../../components/AlertModal/index";

export interface DataRow {
  id: number;
  photo: string;
  name: string;
  email: string;
  role: Role;
}

function UsuariosTab() {

  const elemPerPage = 15;
  const [searchText, setSearchText] = useState('');
  const urlGetDatos = '/api/usuarios';
  const [users, setUsers] = useState<DataRow[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<User[]>();
  const navigate = useNavigate() as NavigateFunction;
  const { token, user } = useAuth();
  const baseURL =  `${appConfig.apiConfig.baseURL}`;
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // Filtrar users según el texto de búsqueda
  const filteredUsers = useMemo(() => {
    return users.filter(actuacion =>
      Object.values(actuacion).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [users, searchText]);

  
  const columns: TableColumn<DataRow>[] = useMemo(
    () => [
      {
        name: 'Imagen',
        cell: row => (
          <div className="table-img">
            <img src={row.photo != '' ? baseURL + row.photo : DefaultAvatarIcon} alt="Foto Perfil"/>
          </div>
        ),
        width: '10%',
      },
      {
        name: 'Nombre',
        selector: (row) => row.name,
        sortable: true,
        width: '25%'
      },
      {
        name: 'Correo',
        selector: (row) => row.email,
        sortable: true,
        width: '25%'
      },
      {
        name: 'Rol',
        selector: (row) => row.role?.label,
        sortable: true,
        width: '25%'
      },
      {
        name: 'Editar',
        cell: row =>
          user.permisos.some((permiso: any) => permiso.nombre === 'edit-users') && (
            <div className="centered-header">
              <img
                src={EditIcon}
                alt="Añadir"
                onClick={() => navigate(`/gestion-usuarios/usuarios/editar/${row.id}`, { state: { from: 'Usuarios' } })}
              />
            </div>
          ),
        width: '5.5%',
      },
      {
        name: 'Eliminar',
        cell: row =>
          user.permisos.some((permiso: any) => permiso.nombre === 'delete-users') && (
            <img src={DeleteIcon} alt="Eliminar" onClick={() => handleDelete(row)} style={{ marginLeft: '15px' }}/>
          ),
        width: '6%',
      },
    ],
    [selectedRows],
  );

  const filteredColumns = columns.filter((column: any) => {
    if (column.name === 'Editar') {
      return user.permisos.some((permiso: any) => permiso.nombre === 'edit-users');
    } else if (column.name === 'Eliminar') {
      return user.permisos.some((permiso: any) => permiso.nombre === 'delete-users');
    }
    return true;
  });

  const handleDelete = (user: User) => {
    const config = {
      title: `Eliminar usuario ${user.name}`,
      description: 'Al continuar, se perderán los datos y no podrás recuperarlos',
      type: 'warning' as AlertModalType,
      onSubmit: async () => {
        await confirmDelete(user.id.toString());
      },
      onClose: handleClose
    };

    setModalConfig(config);
  };

  const confirmDelete = async(id: string) => {
    let config: any;

    // Llama a la función onDelete pasándole formData
    if (id) {
      const response = await deleteData(`${urlGetDatos}/${id}`, navigate, token);
      if(response.status === 200) {
        config = {
          title: `Usuario borrado correctamente`,
          type: 'ok' as AlertModalType,
          onSubmit: () => {
            handleClose();
            window.location.reload();
          }
        };
      } else {
        if (response?.data.error) {
          config = {
            title: 'Error al eliminar: ' + response?.data.error,
            type: 'error'
          };
        }
      }
      setModalConfig(config);
    }
  };

  const handleClose = () => {
    setModalConfig(null);
  };

  // Llamada a la API para recibir los datos
  const fetchData = async (offset: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlGetDatos}?limit=${elemPerPage}&offset=${offset}`, navigate, token);
      if (response && response.status === 200) {
        const transformedData = response.data.usuarios.map((item: any) => {

          return {
            id: item.id,
            photo: item.foto,
            name: item.name,
            email: item.email,
            role: {
              id: 1,
              name: item.rol.nombre,
              label:item.rol.label,
              description: item.rol.descripcion
            }
          }

        });

        setUsers(transformedData);
        setTotalRows(response.data.total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  return (
      <div>
        <div className="header-buttons">
          {(user && user.permisos.some((permiso: any) => permiso.nombre === 'create-users')) && (
            <Button color="white" onClick={() => navigate(`/gestion-usuarios/usuarios/crear`, { state: { from: 'Usuarios' } } )}>
              <img src={AddIcon} alt="Añadir usuario" />
              Añadir usuario
            </Button>
          )}

          <Input placeholder="Buscar" iconSrc={SearchIcon} onChange={handleSearch} />
        </div>

        {(filteredUsers && filteredUsers.length > 0) && (
          <DataTable
            columns={filteredColumns}
            data={filteredUsers}
            pagination
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            responsive
            paginationPerPage={elemPerPage}
            paginationTotalRows={totalRows}
            paginationComponent={CustomPagination}
          />
        )}

        {(users && searchText !== '' && (!filteredUsers || filteredUsers.length == 0)) && (
          <div className="no-data">No hay datos con los filtros aplicados</div>
        )}

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={(modalConfig.onSubmit ? modalConfig.onSubmit : handleDelete) as (() => void) | undefined}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleClose}
          />
        )}

      </div>
  );
}

export default UsuariosTab;